import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import CustomTable from '../../Components/CustomTable'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import BASEURL from '../../Config/global';
import axios from 'axios';
import { Modal } from 'react-bootstrap'
import { questionModal, successModal } from '../../Components/CustomModal';
import CustomLoader from '../../Components/CustomLoader';
import './style.css';
import { AddImageComponent } from '../CategoryManagement/ImageComponent'
import CustomInput from '../../Components/CustomInput'
import CustomPagination from '../../Components/CustomPagination'
import CustomFilters from '../../Components/CustomFilters'
import { disabledButtonByPermission } from '../../Components/Permissions'
import { PermissionsContext } from '../../Layout/PermissionContext'

const FeaturedMix = () => {
  const { permissions } = useContext(PermissionsContext)
    const [config, setConfig] = useState({ error: false, msg: '', value: '', loader: false });
    const [formData, setFormData] = useState({});
    const [categoryModalConfig, setCategoryModalConfig] = useState({ error: false, msg: '', value: '', loader: false });
    const [soundMixList, setSoundMixList] = useState([]);
    const [categoryMixList, setCategoryMixList] = useState([]);
    const [subCategoryMixList, setSubCategoryMixList] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [soundImage, setSoundImage] = useState(null);
    const [updatedFeatureMix, setUpdatedFeatureMix] = useState([]);
    const [enteries, setEnteries] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        getFeaturedListByEmail()
    }, [enteries, offset])

    const modulesWisePermission = (condition) =>{
        if(!permissions){
          return 'd-none'
        }
        return disabledButtonByPermission(permissions, condition)
      }
    const header = [
        {
            key: "ids",
            title: "Publish",
        },
        {
            key: "id",
            title: "Mix ID",
        },
        {
            key: "title",
            title: "Title",
        },
        {
            key: "ids",
            title: "Action",
        },
    ];



    const getFeaturedListByEmail = async () => {
        // fetch data from API with email
        // update state with fetched data
        try {
            if (config?.value === "" || config?.value === null) {
                return
            }
            setConfig({ ...config, loader: true, error: false });
            const response = await axios.get(`${BASEURL}/api/sounds/user_mix?limit=${enteries}&offset=${offset}&search=${config?.value}`);
            setConfig({ ...config, loader: false });
            if (response.data.count === 0 && response.data.data.length === 0) {
                setSoundMixList([])
                setConfig({ ...config, error: true, msg: "No Record Found" });
                setTotalCount(0);
            } else {
                setSoundMixList(response?.data?.data)
                setTotalCount(response?.data?.count);
            }
        } catch (error) {
            setSoundMixList([])
            setConfig({ ...config, error: true, msg: "No Record Found", loader: false });
        }
    }

    // handle the modal window and set the mixid.
    const openCategoryModal = (mixer_id) => {
        setShowCategoryModal(true);
        setFormData({ ...formData, "mixer_id": mixer_id });
        getMixConfigurationById(mixer_id);
        getAllMixCategories();
    }

    const getMixConfigurationById = async (id) => {
        // fetch data from API with email
        // update state with fetched data
        try {
            setCategoryModalConfig({ ...config, loader: true, error: false });
            // http://backend.dev.relax.scofa.com/api/sounds/set-mixer-category/?mixer_id=2454'
            const response = await axios.get(`${BASEURL}/api/sounds/set-mixer-category/?mixer_id=${id}`);
            if (!response.data.error) {
                setCategoryModalConfig({ ...config, loader: false, error: false });
                setFormData(response?.data?.data);
                if (response?.data?.data?.parent_category_id) {
                    getMixSubCategoriesById(response?.data?.data?.parent_category_id);
                }
            }
        } catch (error) {
            setCategoryModalConfig({ ...config, error: true, msg: error.message, loader: false });
        }
    }

    const getAllMixCategories = async () => {
        try {
            const response = await axios.get(`${BASEURL}/api/sounds/mixFeatureCategories`);
            setCategoryMixList(response?.data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getMixSubCategoriesById = async (id) => {
        try {
            const response = await axios.get(`${BASEURL}/api/sounds/mixsubcategories/${id}`);
            setSubCategoryMixList(response?.data?.data);
        } catch (error) {
            console.error(error);
        }
    }

    // const updateMixSortingPositionConfirmation = async () => {
    //     // fetch data from API with email
    //     // update state with fetched data
    //     try {
    //         questionModal.fire({
    //             title: `Do you want to update this mix list?`,
    //             confirmButtonText: "Update",
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 setLoader(true);
    //                 updateMixSortingPosition();
    //             }
    //         });
    //     } catch (error) {
    //         console.log(error)
    //         // setCategoryModalConfig({ ...config, msg: error.message, loader: false });
    //     }
    // }

    // const updateMixSortingPosition = async () => {
    //     // fetch data from API with email
    //     // update state with fetched data
    //     try {
    //         let body = {
    //             // user_id: soundMixList[0]['user'],
    //             mixture_data: updatedFeatureMix
    //         }
    //         const response = await axios.patch(`${BASEURL}/api/sounds/update-featured-status/`, body);
    //         setLoader(false);
    //         if (!response.data.error && response.data.status === 200) {
    //             successModal.fire({
    //                 text: `Featured Mix List Updated Successfully`,
    //                 confirmButtonText: "Continue",
    //             });
    //             setSoundMixList([]);
    //             setConfig({ ...config, loader: false, value: '' });
    //         }
    //     } catch (error) {
    //         setLoader(false);
    //         setConfig({ ...config, loader: false });
    //         console.log(error);
    //         // setCategoryModalConfig({ ...config, msg: error.message, loader: false });
    //     }
    // }

    const handleUpdateAdd = () =>{
        questionModal
            .fire({
                title: `Do you want to update this mix?`,
                confirmButtonText: "Yes",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    addCategoryAndSubCategory();
                }
            });
    }

    const addCategoryAndSubCategory = async () => {
        // fetch data from API with email
        // update state with fetched data
        try {
            if (!formData?.title || formData?.title === "" || !formData?.description || formData?.description === "") {
                setCategoryModalConfig({ ...config, error: true, msg: 'Please fill all filds' });
                return;
            }
            if (!formData?.parent_category_id || formData?.parent_category_id === undefined || formData?.parent_category_id === "undefined") {
                setCategoryModalConfig({ ...config, error: true, msg: 'Parent Category is required' });
                return
            }
            if (!formData?.banner || formData?.banner === undefined || formData?.banner === "undefined") {
                setCategoryModalConfig({ ...config, error: true, msg: 'Banner is required' });
                return
            }
            if (formData?.parent_category_id || formData?.sub_category_id || formData?.is_featured || formData?.title || formData?.description) {
                setCategoryModalConfig({ ...config, error: false });
                setLoader(true);
                let body = {
                    "mixer_id": formData?.id,
                    "parent_category_id": formData?.parent_category_id,
                    "sub_category_id": formData?.sub_category_id,
                    "featured_mix_status": formData?.featured_mix_status,
                    "banner": formData?.banner,
                    "title": formData?.title,
                    "description": formData?.description,
                }
                // http://backend.dev.relax.scofa.com/api/sounds/set-mixer-category/
                const response = await axios.patch(`${BASEURL}/api/sounds/set-mixer-category/`, body);
                // console.log(response.data)
                setLoader(false);
                if (!response.data.error && response.data.status === 200) {
                    successModal.fire({
                        text: `Mix updated successfully`,
                        confirmButtonText: "Continue",
                    });
                    getFeaturedListByEmail()
                    setConfig({ ...config, loader: false });
                    setCategoryModalConfig({ error: false, msg: '', value: '', loader: false });
                    setShowCategoryModal(false);
                    setFormData({});
                }
            } else {
                setCategoryModalConfig({ ...config, error: true, msg: 'No Changes Exist!' });
            }
        } catch (error) {
            setLoader(false);
            setCategoryModalConfig({ ...config, msg: error.message, error: true });
            console.log(error);
        }
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file === null || file === "undefined") {
            // setSoundImage(null);
            // setFormData({ ...formData, file_upload: null })
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setFormData({ ...formData, banner: reader.result })
                setSoundImage(reader.result);
            };
        }
    };

    const handleRemoveImage = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setFormData({ ...formData, banner: null })
        setSoundImage(null)
    }

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }
    const handleChangeEntries = (no) => {
        setOffset(0)
        setEnteries(no)
    }

    return (
        <>

            <DashboardLayout>
                <div className='container-fluid'>
                    {/* TOP ROW / SEARCH BAR */}
                    <div className="row mb-3">
                        <div className="col-8">
                            <h2 className="mainTitle">Featured Mixes</h2>
                        </div>
                        <div className="col-4 text-end">
                            {/* <button type="button"
                                disabled={updatedFeatureMix.length > 0 ? false : true}
                                className={`customButton ${soundMixList.length > 0 ? 'primaryButton' : ''}`}
                                onClick={updateMixSortingPositionConfirmation}>
                                Update Mix
                            </button> */}
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <CustomFilters
                                    enteries={enteries}
                                    totalCount={totalCount}
                                    entriesFilter={true}
                                    setEnteries={handleChangeEntries}
                                />
                            </div>
                            <div className="col-6 my-2 text-end">
                                <form autoComplete="off" onSubmit={(event) => {
                                    event.preventDefault()
                                    getFeaturedListByEmail()
                                }} className="searchWrapper w-100" style={{ maxWidth: '500px' }}>
                                    <input
                                        type="text"
                                        placeholder="Please enter user email"
                                        name="search"
                                        className="filterInput searchInput w-100"
                                        autoComplete='off'
                                        value={config?.value}
                                        onChange={(event) => {
                                            setConfig({ ...config, value: event.target.value?.trim() });
                                        }}
                                    />
                                    <button type='submit' className="searchButton notButton">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <CustomTable headers={header}>
                        <tbody>
                            {(!config.loader && soundMixList.length > 0) ? (
                                soundMixList?.map((item, index) => (
                                    <tr key={item.id}>
                                        <td width={150}>
                                            <div className='mt-2'>
                                                <input
                                                    type="checkbox"
                                                    name="featured"
                                                    key={`featured-${index}`}
                                                    checked={item.featured_mix_status ? true : false}
                                                // onChange={() => {
                                                //     let temp = [...soundMixList]
                                                //     temp[index] = { ...temp[index], featured_mix_status: !item.featured_mix_status }
                                                //     setSoundMixList(temp);
                                                //     let tempUpdatedFeaturMixes = [...updatedFeatureMix]
                                                //     let findIndex = tempUpdatedFeaturMixes.findIndex(f => f.id === item.id)
                                                //     if (findIndex !== -1) {
                                                //         tempUpdatedFeaturMixes[findIndex] = { id: item.id, featured_mix_status: !item.featured_mix_status }
                                                //     } else {
                                                //         tempUpdatedFeaturMixes.push({ id: item.id, featured_mix_status: !item.featured_mix_status })
                                                //     }
                                                //     setUpdatedFeatureMix(tempUpdatedFeaturMixes)
                                                // }}
                                                />
                                            </div>

                                        </td>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{<FontAwesomeIcon
                                            className={`${modulesWisePermission('can_edit')}`}
                                            icon={faEdit}
                                            onClick={() => openCategoryModal(item.id)} />
                                        }</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    {config.error && <td colSpan="3" className="text-center">{config.msg}</td>}
                                    {config.loader && <td colSpan="3" className="text-center"> <div className="spinner-border" role="status"></div></td>}
                                </tr>
                            )}
                        </tbody>
                    </CustomTable>
                    <div className="row mb-3">
                        <div className="col-12">
                            <CustomPagination
                                enteries={enteries}
                                totalCount={totalCount}
                                setCurrentPage={setOffset}
                                currentPage={offset}
                            />
                        </div>
                    </div>

                </div>
            </DashboardLayout>

            {/* Show Category Modal */}
            <Modal
                show={showCategoryModal}
                centered
                className="legal-form-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="w-100 d-flex flex-column align-items-center px-2">
                        <div className="d-flex justify-content-between w-100 py-2">
                            <h2 className="mainTitle">Add Category</h2>
                            <div className="text-end ms-auto">
                                <button
                                    className="closeButton notButton ms-auto"
                                    onClick={() => {
                                        setShowCategoryModal(false);
                                        setFormData({});
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="cross-icon"
                                        color="white"
                                    />
                                </button>
                            </div>
                        </div>
                        <br />

                        <div className="row w-100">
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-4'>
                                        <CustomInput
                                            label="Title"
                                            labelClass="mainLabel "
                                            required
                                            type="text"
                                            name="title"
                                            value={formData?.title || ""}
                                            placeholder="Enter Title"
                                            inputClass="mainInput"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <p className="mainLabel">Select Category*</p>
                                        <select
                                            name="parent_category_id"
                                            id="parent_category_id"
                                            className="mainInput w-100"
                                            style={{marginTop:'1px'}}
                                            value={formData?.parent_category_id || ""}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({
                                                    ...formData, [name]: Number(value)
                                                });
                                                getMixSubCategoriesById(Number(value));
                                            }}
                                        >
                                            <option value={null}> Select </option>
                                            {categoryMixList.length > 0 && categoryMixList.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-4'>
                                        <p className="mainLabel">Select Sub Category</p>
                                        <select
                                            name="sub_category_id"
                                            id="sub_category_id"
                                            className="mainInput w-100"
                                            style={{marginTop:'1px'}}
                                            value={formData?.sub_category_id || ""}
                                            onChange={(event) => {
                                                const { name, value } = event.target;
                                                setFormData({
                                                    ...formData, [name]: Number(value)
                                                });
                                            }}
                                        >
                                            <option value={null}> Select </option>
                                            {subCategoryMixList.length > 0 && subCategoryMixList.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <AddImageComponent
                                    title={`Image`}
                                    inputClass="asset-upload w-100 h-100"
                                    soundImage={formData?.banner || null}
                                    handleImageChange={(event) => handleImageChange(event)}
                                    handleRemoveImage={(event) => handleRemoveImage(event)}
                                />
                            </div>
                            <div className='col-8'>
                                <div className='row'>
                                    <div className="col-12">
                                        <p className="mainLabel">Description</p>
                                        <textarea
                                            label="Description"
                                            labelClass="mainLabel"
                                            required
                                            type="text"
                                            style={{ minHeight: '260px' }}
                                            name="description"
                                            className="mainInput"
                                            value={formData?.description || ""}
                                            placeholder="Enter Description"
                                            inputClass="mainInput"
                                            onChange={handleChange}
                                            resize="vertical" // or "both" for horizontal and vertical resizing
                                        />
                                    </div>
                                    <div className="col-12 mb-2">
                                        <div className='mt-2'>
                                            <input
                                                type="checkbox"
                                                name="featured"
                                                id="featured"
                                                checked={formData.featured_mix_status ? true : false}
                                                onChange={() => {
                                                    setFormData({
                                                        ...formData, 'featured_mix_status': !formData.featured_mix_status
                                                    });
                                                }}
                                            />
                                            <label htmlFor="featured" className="mainLabel ms-1">
                                                Publish
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {categoryModalConfig?.error && <small className="text-danger mt-3">{categoryModalConfig.msg}</small>}
                        <div className="pt-5 d-flex gap-3 justify-content-center mb-3">
                            <button
                                type='button'
                                style={{ backgroundColor: '#ffffff1f' }}
                                className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0'
                                onClick={handleUpdateAdd}
                            >
                                UPDATE
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Loader */}
            {loader && <div className='featured-loader'><CustomLoader /></div>}
        </>
    )
}

export default FeaturedMix