import { useState, useEffect, useContext } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import axios from "axios";

import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faEdit,
  faEllipsisV,
  faEye,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { DashboardLayout } from "../../Layout/DashboardLayout";

import CustomTable from "./../../Components/CustomTable";
import { placeholderImage } from "../../Assets/images";

import "./style.css";
import { questionModal, successModal } from "../../Components/CustomModal";
import CustomFilters from "../../Components/CustomFilters";
import BASEURL from "../../Config/global";
import CustomPagination from "../../Components/CustomPagination";
import moment from "moment";
import { useDebounce } from 'use-debounce';
import CustomInput from "../../Components/CustomInput";
import { ExportToExcel } from "../../Components/ExportToExcel";
import { PermissionsContext } from "../../Layout/PermissionContext";
import { disabledButtonByPermission } from "../../Components/Permissions";

const UserManagement = () => {
  const { permissions } = useContext(PermissionsContext)
  const [data, setData] = useState([]);
  const params = useParams()
  const [enteries, setEnteries] = useState(10);
  const [offset, setOffset] = useState(0);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [filterSearchValue, setFilterSearchValue] = useState("");
  const [sort, setSort] = useState("all");
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [hideFilters,setHideFilters] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [filterSortValues, setfilterSortValues] = useState([
    {
      text: 'Created',
      value: 'lastest'
    },
    {
      text: 'Last Active',
      value: 'last_active'
    }
  ]);
  const [filterSortedValue, setfilterSortedValue] = useState('lastest');
  const [value] = useDebounce(filterSearchValue, 2000)

  useEffect(() => {
    document.title = "Relax Scofa | User Management";
  }, []);

  useEffect(() => {
    if (currentPage != undefined) {
      setOffset(currentPage);
    }
  }, [currentPage])

  const modulesWisePermission = (condition) => {
    if (!permissions) {
      return 'd-none'
    }
    return disabledButtonByPermission(permissions, condition)
  }

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await axios.get(
  //          `${BASEURL}/api/user/`
  //       );

  //         console.log(response);
  //         setData(response.data.data)

  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   fetchData();
  // }, [enteries, filterSearchValue, dateTo]);

  useEffect(() => {
    fetchData();
  }, [enteries,dateFrom, dateTo, offset, value, filterSortedValue, params]);
  
  // useEffect(() => {
  //   fetchData();
  // }, [value]);

  const fetchData = async () => {
    try {
      setLoader(true)
      let URL = `/api/user/?limit=${enteries}&offset=${offset}&start_date=${dateFrom}&end_date=${dateTo}&search=${filterSearchValue}&sortby=${filterSortedValue}`
      if (params.type) {
        URL += `&type=${params.type}`
      }
      const response = await axios.get(
        `${BASEURL}${URL}`
      );
      if (response.data.error != true) {
        setData(response.data.data);
        setTotalCount(response.data.count);
      } else {
        console.log(response.data.message);
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.error(error);
    }
  }
  const deleteArticle = async (id) => {
    setLoader(true)
    try {
      const response = await axios.delete(`${BASEURL}/api/user/${id}`);
      if (response.data.error === false) {
        successModal.fire({
          text: "User Deleted Successfully",
          confirmButtonText: "Continue",
        });
        setData(data.filter((item) => item.id !== id));
        setLoader(false);
        Navigate("/user-management");
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteArticleAction = (id, first_name, last_name, email) => {
    questionModal
      .fire({
        title: `Do you want to delete this User?`,
        html: `<p>${first_name + " " + last_name}</p><p>${email}</p>`,
        confirmButtonText: "Yes",
        showClass: {
          popup: `
            user_delete_modal
          `
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteArticle(id);
          successModal.fire({
            text: `${first_name} User has been deleted`,
            confirmButtonText: "Continue",
          });
        }
      });
  };

  const articleHeader = [
    {
      key: "id",
      title: "User Id",
    },
    {
      key: "user_name",
      title: "User Name",
    },
    // {
    //   key: "last_name",
    //   title: "Last Name",
    // },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "subscription_type",
      title: "Subscription",
    },
    {
      key: "household_parent",
      title: "Household Parent",
    },
    {
      key: "propagation_parent",
      title: "Propagation Parent",
    },
    {
      key: "noofchild",
      title: "No. of Child",
    },
    {
      key: "couponCode",
      title: "Promo Code",
    },
    {
      key: "offerCode",
      title: "Offer Code",
    },

    {
      key: "house",
      title: "User Type",
    },
    {
      key: "dateTime",
      title: "Created",
    },
    {
      key: "active",
      title: "Last Active",
    },
    {
      key: "actions",
      title: "Actions",
    },
  ];

  // if (data[0] && data[0]['date_joined']) {
  //   console.log(new Date(data[0]['date_joined']).toLocaleString());
  // }
  const handleChangeEntries = (no) => {
    setOffset(0)
    setEnteries(no)
  }

  if (loader) {
    return (
      <DashboardLayout class="d-flex flex-column">
          <div className="loaderWrapper-2">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </DashboardLayout>
    )
  }
  return (
    <>
      <DashboardLayout class="d-flex flex-column">
        <div className="container-fluid d-flex flex-column flex-grow-1">
          <div className="row mb-3">
            <div className="col-lg-8 mb-2">
              <h2 className="mainTitle">Users</h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-5">
            <CustomFilters
                enteries={enteries}
                totalCount={totalCount}
                entriesFilter={true}
                setEnteries={handleChangeEntries}
                filterSort={true}
                filterSortValues={filterSortValues}
                setfilterSortedValue={setfilterSortedValue}
                filterSortedValue={filterSortedValue}
              />
            </div>
            <div className="col-7 d-flex justify-content-end ">
              <div className="d-flex  my-3 gap-4 align-items-start">
                {
                  hideFilters && (
                    <>
                      <div className="d-flex align-items-center ">
                        <label className="mb-2 me-3">From:</label>
                        <CustomInput
                          type="date"
                          name="title"
                          value={dateFrom}
                          placeholder="Enter Title"
                          inputClass="filterInput searchInput"
                          onChange={(e)=>setDateFrom(e.target.value)}
                          min="1900-01-01" 
                          max="2099-12-31"
                        />
                      </div>
                      <div className="ms-4 d-flex align-items-center">
                        <label className="mb-2 me-3">To:</label>
                        <CustomInput
                          type="date"
                          name="title"
                          value={dateTo}
                          placeholder="Enter Title"
                          inputClass="filterInput searchInput"
                          onChange={(e)=>setDateTo(e.target.value)}
                          min="1900-01-01" 
                          max="2099-12-31"
                        />
                      </div>
                    </>
                  )
                }
                
                </div>
               
             <div className="d-flex">
             <div className="mt-3 ms-2">

                <button className="btn  text-white border-0" onClick={() => setHideFilters(!hideFilters)}><FontAwesomeIcon icon={faCalendarDays} size="xl" /></button>
             </div>
             <CustomFilters
                filterSearch={true}
                filterSearchValue={filterSearchValue}
                setFilterSearchValue={setFilterSearchValue}
              />
              <div className="mt-4 ms-4">
              <ExportToExcel  apiData={data} fileName={"UserManagement"} dateFrom={dateFrom} dateTo={dateTo} /> 
              </div>
             </div>
            </div>
          </div>
          <div className="row d-flex flex-column flex-grow-1">
            <div className="col-12 user-management-table flex-grow-1 position-relative overflow-auto">
              <CustomTable headers={articleHeader}>
                <tbody>
                  {data &&
                    data.map((item, index) => {
                      if (item?.email != null) {
                        let last_active_login = " ";
                        let date_joined = " ";

                        if (moment(item?.last_active_login, moment.ISO_8601, true).isValid()) {
                          last_active_login = <td>{moment(item?.last_active_login).format('MM-DD-YYYY')}<br />{moment(item?.last_active_login).format('HH:mm:ss')}</td>;
                        }
                        if (moment(item?.date_joined, moment.ISO_8601, true).isValid()) {
                          date_joined = <td>{moment(item?.date_joined).format('MM-DD-YYYY')}<br />{moment(item?.date_joined).format('HH:mm:ss')}</td>;
                        }
                        return (
                          (
                            <tr key={index}>
                              <td>{new Date(item?.date_joined).toISOString().split('T')[0].split('-')[0].slice(-2) + new Date(item?.date_joined).toISOString().split('T')[0].split('-')[1] + new Date(item?.date_joined).toISOString().split('T')[0].split('-')[2] + new Date(item?.date_joined).toISOString().split('T')[1].split(':')[0] + new Date(item?.date_joined).toISOString().split('T')[1].split(':')[1] + item?.id}</td>
                              <td>{item?.first_name + ' ' + item?.last_name}</td>
                              {/* <td>{item?.last_name}</td> */}
                              <td>{item?.email}</td>
                              <td>{item?.subscription_type && item?.subscription_type != null ? item?.subscription_type : "-"}</td>
                              <td>{item?.parent && item?.parent != null ? item?.parent?.email : "-"}</td>
                              <td>{item?.propagation_parent?.email}</td>
                              {/* No of childs */}
                              <td>
                                {
                                  Number(item?.number_of_childs)>0 ? <Link
                                    to={`/reportings/users/${item.id}`}
                                    // className={`tableAction`}
                                    style={{ textDecoration: 'none', color: 'white' }}
                                    target="_blank"
                                  >
                                    {item?.number_of_childs}
                                  </Link>:"-"
                                }
                              </td>
                              <td>{item?.code_used_while_signup_code}</td>
                              <td>{item?.propagation_code}</td>
                              <td>{item?.status === "sub_user" ? "Household" : item?.status}</td>

                              {/* {date_joined} */}
                              <td>{item?.date_joined != null && moment.utc(item?.date_joined).local().format("MM-DD-YYYY")}<br /> {moment.utc(item?.date_joined).local().format("HH:mm:ss")} </td>
                              <td>{item?.last_active_login != null && moment.utc(item?.last_active_login).local().format("MM-DD-YYYY")} <br />
                                {item?.last_active_login != null ? moment.utc(item?.last_active_login).local().format("HH:mm:ss") : '-'} </td>
                              {/* <td>{last_active_login} </td> */}
                              {/* <td>{ + '\n' + new Date(item?.last_active_login).toISOString().split('T')[1].slice(0, 8) : '-'}</td> */}
                              {/* <td>{item?.last_active_login}</td> */}
                              {/* <td>{new Date(item?.last_active_login).toISOString().split('T')[0].split('-')[1] + '-' + new Date(item?.last_active_login).toISOString().split('T')[0].split('-')[2] + '-' + new Date(item?.last_active_login).toISOString().split('T')[0].split('-')[0]}</td> */}
                              {/* <td>{new Date(item?.last_active_login).toISOString().split('T')[0].split('-')[1] + '-' + new Date(item?.last_active_login).toISOString().split('T')[0].split('-')[2] + '-' + new Date(item?.last_active_login).toISOString().split('T')[0].split('-')[0]} <br /> {new Date(item?.last_active_login).toISOString().split('T')[1].slice(0, 8)}</td> */}
                              <td>
                                <Dropdown className="tableDropdown">
                                  <Dropdown.Toggle
                                    variant="transparent"
                                    className="notButton classicToggle"
                                  >
                                    <FontAwesomeIcon icon={faEllipsisV} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                    align="end"
                                    className="tableDropdownMenu"
                                  >
                                    <Link
                                      to={`/user-management/user-detail/${item.id}`}
                                      className={`tableAction ${modulesWisePermission('can_view')}`}
                                    >
                                      <FontAwesomeIcon
                                        icon={faEye}
                                        className="tableActionIcon"
                                      />
                                      View
                                    </Link>
                                    <Link
                                      to={`/user-management/edit-detail/${item.id}`}
                                      className={`tableAction ${modulesWisePermission('can_edit')}`}
                                    >
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="tableActionIcon"
                                      />
                                      Edit
                                    </Link>
                                    <button
                                      className={`tableAction ${modulesWisePermission('can_delete')}`}
                                      onClick={() => {
                                        deleteArticleAction(item.id, item.first_name, item.last_name, item.email);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        className="tableActionIcon"
                                      />
                                      Delete
                                    </button>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )
                        )
                      } else {
                        return ''
                      }
                    }
                    )}
                </tbody>
              </CustomTable>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <CustomPagination
                enteries={enteries}
                totalCount={totalCount}
                setCurrentPage={setOffset}
                currentPage={offset}
              />
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};
export default UserManagement;
