import React, { useContext, useEffect, useState } from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import './style.css'
import BASEURL from '../../Config/global'
import axios from 'axios'
import CustomInput from '../../Components/CustomInput'
import { unstable_usePrompt as usePrompt } from 'react-router-dom'
import { questionModal, successModal } from '../../Components/CustomModal'
import { PermissionsContext } from '../../Layout/PermissionContext'
import { disabledButtonByPermission } from '../../Components/Permissions'
const PromptsManagement = () => {
      const { permissions } = useContext(PermissionsContext)
    
    const [formData, setFormData] = useState({
        "Mix Message": {
            "delete_mix": "Delete mix message",
            "delete_mix_nofitication": "Delete mix notification",
            "mix_saved_notification": "Mix saved notification",
        },
        "General message": {
            "logout_message": "Logout user message",
            "logout_message_notification": "User Logout notification",
            "profile_edit_success": "Edit Profile success message",
            "subscription_cancel_message": "Subscription cancel message",
            "subscription_cancel_notification": "Subscription cancel notification",
            "link_copied_notification": "Link copied notification",
            "email_invite_message": "Email invite message",
            "payment_success_message": "Payment Success message",
            "payment_decline_message": "Payment Decline message",
            "invalid_card_details":"Invalid card details",
            "payment_paid_successfully_message":"payment_paid_successfully_message",
            "payment_saved_successfully_message":"payment_saved_successfully_message",

        },
        "Household Members": {
            "household_remove_message": "Remove household user message",
            "household_remove_notification": "Remove household user notification",
        },
        "Form Messages": {
            "signup_success_notification": "Signup success notification",
            "user_exist_not_message": "User does not exist message",
            "email_verfication_message": "Email verification messasge",
            "email_required_message": "Email required message",
            "password_required_message": "Password required message",
            "password_validate_message": "Password validate message",
            "confirm_password_required_message": "Confirmed Password required message",
            "confirm_password_validate_message": "Confirmed Password validate message",
            "password_length_message": "Password length message",
            "password_not_match_message": "Password not match message",
            "forget_otp_sent_msg": "Otp wrong message",
            "reset_password_notification": "Reset password notification",
            "firstname_validate": "First Name validate",
            "lastname_validate": "Last Name validate",
            "country_validate": "Country Name validate",
            "birth_day_validate": "Birthday validate",
            "invalid_creds_message": "Invalid creds message",

        },
        "Share&Earn Messages": {
            "financial_profile_agreement_message": "Agreement message",
        },
        "Mobile Messages": {
            "login_to_web_app": "Web app login message",
        },
    });
    const [selectedPrompt, setSelectedPrompt] = useState({});
    const [updatedValues, setUpdatedValues] = useState({})

    useEffect(() => {
        fetchData()
    }, [])

    const modulesWisePermission = (condition) => {
        if (!permissions) {
            return 'd-none'
        }
        return disabledButtonByPermission(permissions, condition)
    }

    async function fetchData() {
        try {
            let url = `${BASEURL}/api/misc/keys/`;
            const response = await axios.get(url);
            if (!response.data.error) {
                let tranformReponse = {}
                const messages = response.data.data
                for (let index = 0; index < messages.length; index++) {
                    const element = messages[index];
                    tranformReponse ={...tranformReponse, ...element}
                }
                setSelectedPrompt(tranformReponse)
            } else {
                console.log(response.data.message);
            }

        } catch (error) {
            console.error(error);
        }
    }


    const handleUpdateMessages = async () => {
        questionModal
            .fire({
                title: "Do you want to update the prompts?",
                confirmButtonText: "Update",
            }).then(async(result) => {
                if (result.isConfirmed) {
                    let url = `${BASEURL}/api/misc/keys/`;
                    const response = await axios.patch(url, updatedValues, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    if (!response.data.error) {
                        successModal.fire({
                            text: 'Prompts updated Successfully',
                            confirmButtonText: "Continue",
                        });
                    }
                }
            })
    }
    return (
        <DashboardLayout>
            <div className="row mb-3">
                <div className="col-lg-8 mb-2">
                    <h2 className="mainTitle">{"Prompts"} </h2>
                </div>
                <div className="col-lg-4 text-end mb-2">
                    <button
                        onClick={handleUpdateMessages}
                        className={`customButton primaryButton ${modulesWisePermission('can_edit')}`}
                    >
                        Update
                    </button>
                </div>
            </div>
            <div className='row'>
                {
                    Object.keys(selectedPrompt).length > 0 ? Object.keys(formData).map((key, index) => {
                        return (<div className='col-12 my-4' key={key}>
                            {
                                index !== 0 && <hr />
                            }
                            <h4 className='my-3'>{key}</h4>
                            <div className='row'>
                                {
                                    Object.keys(formData[key]).length > 0 ? (
                                        Object.keys(formData[key]).map(subKey => (
                                            <div className="col-lg-4" key={subKey}>
                                                <CustomInput
                                                    label={formData[key][subKey]}
                                                    labelClass="mainLabel"
                                                    required
                                                    type="text"
                                                    name={formData[key][subKey]}
                                                    value={selectedPrompt[subKey]}
                                                    placeholder="Enter Title"
                                                    inputClass="mainInput"
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        setSelectedPrompt({ ...selectedPrompt, [subKey]: newValue })
                                                        setUpdatedValues({ ...updatedValues, [subKey]: newValue })
                                                    }}
                                                />
                                            </div>
                                        ))
                                    ) : null

                                }
                            </div>
                        </div>)
                    }) : (
                        <div className="center-align">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    )
                }
            </div>

        </DashboardLayout>
    )
}

export default PromptsManagement

