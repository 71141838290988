import React from 'react'
import { DashboardLayout } from '../../Layout/DashboardLayout'

const UnauthorizedResourceError = () => {
  return (
    <DashboardLayout>
      <div class="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
        <p class="text-center">You don't have permission to use this resource.</p>
      </div>
      </DashboardLayout>
  )
}

export default UnauthorizedResourceError