import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronDown, faEllipsisV, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import { Dropdown } from "react-bootstrap";

const CustomFilters = (props) => {
  const calculateShowData = () => {
    if (props?.enteries === 0) {
      return 0
    } else if (props?.enteries <= props.totalCount) {
      return props?.enteries
    } else {
      return props.totalCount ?? 0
    }
  }
  return (
    <>
      <div className="tableFilters">
        <div className="row">
          <div className="col-xl-6 mb-2">
            <div className="d-flex align-items-baseline">
              {props.entriesFilter && (
                <div className="d-flex align-items-baseline gap-2">
                  <p>Show</p>
                  <select
                    className="filterInput"
                    value={props?.enteries}
                    onChange={(event) => {
                      props?.setEnteries(event.target.value);
                    }}
                  >
                    {/* <option value="-1">All</option> */}
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value="2000">2000</option>
                  </select>
                  <p>Entries</p>
                  {
                    props.entriesFilter && <p className="paginationText ms-1 text-nowrap">{calculateShowData()} of {props?.totalCount ?? 0}</p>
                  }
                </div>
              )}
              
            </div>
          </div>
          <div className="col-xl-6 mb-2">
            {props?.filterSearch && (
              <div className="filterWrapper d-md-flex align-items-baseline justify-content-end gap-2">
                <div className="searchWrapper d-flex">
                  {
                    props.isSearchDropdown &&
                    <select
                      className="filterInput"
                      onChange={(event) => {
                        props?.setSort(event.target.value);
                      }}
                    >
                      {props?.isSearchDropdown.map((item, index) => (
                        <option key={index} value={item.title}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  }
                  <input
                    type="text"
                    placeholder="Search"
                    name="search"
                    className="filterInput searchInput"
                    value={props?.filterSearchValue}
                    onChange={(event) => {
                      props?.setFilterSearchValue(event.target.value);
                    }}
                  />
                  <button className="searchButton notButton">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-6 mb-2">
            {props?.dateFilter ? (
              <div className="filterWrapper d-md-flex align-items-baseline gap-2">
                <label className="filterLabel">Sort By:</label>
                <div className="d-flex gap-3">
                  <input
                    type="date"
                    placeholder="From"
                    name="from"
                    className="filterInput"
                    onChange={(event) => {
                      props?.setDateFrom(`${event.target.value}T00:00:00.000000Z`);
                    }}
                  />
                  <input
                    type="date"
                    placeholder="To"
                    name="to"
                    className="filterInput"
                    onChange={(event) => {
                      props?.setDateTo(`${event.target.value}T23:59:59.000000Z`);
                    }}
                  />
                </div>
              </div>
            ) : props?.filterSort && (
              <div className="filterWrapper d-md-flex align-items-baseline gap-2">
                <label className="filterLabel">Sort By:</label>
                <select
                  className="filterInput"
                  onChange={(event) => {
                    props?.setfilterSortedValue(event.target.value);
                  }}
                  value={props?.filterSortedValue}
                >
                  {props?.filterSortValues.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomFilters;

// filterSort
// filterSortValue
// setFilterSortValue

// filterSearch
// filterSearchValue
// setFilterSearchValue

// dateFilter
// filterFrom
// setFilterFrom
// filterTo
// setFilterTo
