import React from "react";
import "./style.css";
import { stats3 } from "../../Assets/images";
import { Link } from "react-router-dom";

const StatsCard = (props) => {
  return (
    <Link className="statsLink" to={`/user-management/${props.item.title.toLowerCase().split(" ").join("-")}`}>
      <div className="statsCard">
        <div className="statsContent">
          <div className="statsImg">
            <img src={stats3} alt="Card Icon" />
          </div>
          <div className="statsData">
            <h3 className="statsNumber">{props.item.value}</h3>
            <p className="statsText">{props.item.title}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StatsCard;
