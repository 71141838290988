import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import { getAccessToken } from "../Util/token";

import AdminLogin from "../Screens/Auth/Login";

import { Dashboard } from "../Screens/Dashboard";

import CategoryManagement from "../Screens/CategoryManagement";
import AddSoundCategory from "../Screens/CategoryManagement/AddSoundCategory";
import AddMeditationCategory from "../Screens/CategoryManagement/AddMeditationCategory";
import AddStoryCategory from "../Screens/CategoryManagement/AddStoryCategory";
import AddArticleCategory from "../Screens/CategoryManagement/AddArticleCategory";
import EditSoundCategory from "../Screens/CategoryManagement/EditSoundCategory";
import EditMeditationCategory from "../Screens/CategoryManagement/EditMeditationCategory";
import EditStoriesCategory from "../Screens/CategoryManagement/EditStoriesCategory";
import EditArticleCategory from "../Screens/CategoryManagement/EditArticleCategory";
import AddFeaturedMixCategory from "../Screens/CategoryManagement/AddFeaturedMixCategory";
import EditFeaturedMixCategory from "../Screens/CategoryManagement/EditFeaturedMixCategory";

import SoundManagement from "../Screens/SoundManagement";
import AddSound from "../Screens/SoundManagement/AddSound";
import SoundDetails from "../Screens/SoundManagement/SoundDetails";
import EditSound from "../Screens/SoundManagement/EditSound";

import MeditationManagement from "../Screens/MeditationManagement";
import AddMeditation from "../Screens/MeditationManagement/AddMeditation";
import MeditationDetails from "../Screens/MeditationManagement/MeditationDetails";
import EditMeditation from "../Screens/MeditationManagement/EditMeditation";

import StoryManagement from "../Screens/StoryManagement";
import AddStory from "../Screens/StoryManagement/AddStory";
import StoryDetails from "../Screens/StoryManagement/StoryDetails";
import EditStory from "../Screens/StoryManagement/EditStory";

import ArticleManagement from "../Screens/ArticleManagement";
import AddArticle from "../Screens/ArticleManagement/AddArticle";
import ArticleDetails from "../Screens/ArticleManagement/ArticleDetails";
import EditArticle from "../Screens/ArticleManagement/EditArticle";

import SubscriptionManagement from "../Screens/SubscriptionManagement";
import AddSubscription from "../Screens/SubscriptionManagement/AddSubscription";
import EditSubscription from "../Screens/SubscriptionManagement/EditSubscription"
import SubscriptionDetails from "../Screens/SubscriptionManagement/SubscriptionDetails"

import AddSpecials from "../Screens/SpecialsManagment/AddSpecials";
import SpecialManagement from "../Screens/SpecialsManagment";
import EditSpecials from "../Screens/SpecialsManagment/EditSpecials";

import UserManagement from "../Screens/UserManagement";
import UserDetails from "../Screens/UserManagement/UserDetail";
import EditUser from "../Screens/UserManagement/EditUser";
import CouponManagement from "../Screens/CouponManagment";
import AddCoupon from "../Screens/CouponManagment/AddCoupon";
import EditCoupon from "../Screens/CouponManagment/EditCoupon";
import CouponDetail from "../Screens/CouponManagment/CouponDetail";
import Media from "../Screens/Media";
import UserRoles from "../Screens/UserRoles";

import AddRoles from "../Screens/ManageRoles/AddRoles";
import ViewRoleDetails from "../Screens/ManageRoles/ViewRoleDetails";
import UnauthorizedResourceError from "../Screens/UnauthorizedResourceError/UnauthorizedResourceError";
import SubCategoryManagment from "../Screens/SubCategoryManagment";


import InfoboxManagement from "../Screens/InfoboxManagement";
import AddInfobox from "../Screens/InfoboxManagement/AddInfobox";
import EditInfobox from "../Screens/InfoboxManagement/EditInfobox";
import InfoboxDetail from "../Screens/InfoboxManagement/InfoboxDetail";
import AddUpload from "../Screens/UploadManagement/AddUpload";
import UploadManagement from "../Screens/UploadManagement";
import AssetsManagement from "../Screens/AssetsUpload";
import AddAsset from "../Screens/AssetsUpload/AddAsset";
import PlatformManagement from "../Screens/PlatformManagement";
import Reportings from "../Screens/Reportings";

import FeaturedMix from "../Screens/FeaturedMix";
import RolesManagement from "../Screens/ManageRoles";
import PublishFeatureMix from "../Screens/PublishFeatureMix";
import PromptsManagement from "../Screens/PromptsManagement";
import LogsManagement from "../Screens/LogsManagement";
import AddEmployees from "../Screens/UserRoles/AddUser";
// import ForgetPassword from "../Screens/Auth/ForgetPassword";
// import ResetPassword from "../Screens/Auth/ResetPassword";
// import VerificationPassword from "../Screens/Auth/VerificationPassword";
// import Error from "../Screens/Error";

const AdminRouter = () => {
  const token = getAccessToken();
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={token ? "/dashboard" : "/login"} />}
        />

        <Route
          path="/login"
          element={
            <ProtectedRoutes>
              <AdminLogin />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/dashboard"
          element={
            <PrivateRoutes>
              <Dashboard />
            </PrivateRoutes>
          }
        />

        <Route
          path="/category-management"
          element={
            <PrivateRoutes>
              <CategoryManagement />
            </PrivateRoutes>
          }
        />

        <Route
          path="/category-management/add-sound-category"
          element={
            <PrivateRoutes>
              <AddSoundCategory />
            </PrivateRoutes>
          }
        />

        <Route
          path="/category-management/add-relax-rest-category"
          element={
            <PrivateRoutes>
              <AddMeditationCategory />
            </PrivateRoutes>
          }
        />

        <Route
          path="/category-management/add-story-category"
          element={
            <PrivateRoutes>
              <AddStoryCategory />
            </PrivateRoutes>
          }
        />

        <Route
          path="/category-management/add-article-category"
          element={
            <PrivateRoutes>
              <AddArticleCategory />
            </PrivateRoutes>
          }
        />

        <Route
          path="/category-management/edit-sound-category/:id"
          element={
            <PrivateRoutes>
              <EditSoundCategory />
            </PrivateRoutes>
          }
        />

        <Route
          path="/category-management/edit-relax-rest-category/:id"
          element={
            <PrivateRoutes>
              <EditMeditationCategory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/category-management/edit-stories-category/:id"
          element={
            <PrivateRoutes>
              <EditStoriesCategory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/category-management/edit-article-category/:id"
          element={
            <PrivateRoutes>
              <EditArticleCategory />
            </PrivateRoutes>
          }
        />

        <Route
          path="/sound-management"
          element={
            <PrivateRoutes>
              <SoundManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/sound-management/add-sound"
          element={
            <PrivateRoutes>
              <AddSound />
            </PrivateRoutes>
          }
        />
        <Route
          path="/sound-management/sound-details/:id"
          element={
            <PrivateRoutes>
              <SoundDetails />
            </PrivateRoutes>
          }
        />
        <Route
          path="/sound-management/edit-sound/:id"
          element={
            <PrivateRoutes>
              <EditSound />
            </PrivateRoutes>
          }
        />

        <Route
          path="/relax-rest-management"
          element={
            <PrivateRoutes>
              <MeditationManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/relax-rest-management/add-relax-rest"
          element={
            <PrivateRoutes>
              <AddMeditation />
            </PrivateRoutes>
          }
        />
        <Route
          path="/relax-rest-management/relax-rest-details/:id"
          element={
            <PrivateRoutes>
              <MeditationDetails />
            </PrivateRoutes>
          }
        />
        <Route
          path="/relax-rest-management/edit-relax-rest/:id"
          element={
            <PrivateRoutes>
              <EditMeditation />
            </PrivateRoutes>
          }
        />

        <Route
          path="/story-management"
          element={
            <PrivateRoutes>
              <StoryManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/story-management/add-story"
          element={
            <PrivateRoutes>
              <AddStory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/story-management/story-details/:id"
          element={
            <PrivateRoutes>
              <StoryDetails />
            </PrivateRoutes>
          }
        />
        <Route
          path="/story-management/edit-story/:id"
          element={
            <PrivateRoutes>
              <EditStory />
            </PrivateRoutes>
          }
        />

        <Route
          path="/article-management"
          element={
            <PrivateRoutes>
              <ArticleManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/article-management/add-article"
          element={
            <PrivateRoutes>
              <AddArticle />
            </PrivateRoutes>
          }
        />
        <Route
          path="/article-management/article-details/:id"
          element={
            <PrivateRoutes>
              <ArticleDetails />
            </PrivateRoutes>
          }
        />
        <Route
          path="/article-management/edit-article/:id"
          element={
            <PrivateRoutes>
              <EditArticle />
            </PrivateRoutes>
          }
        />

        <Route
          path="/subscription-management"
          element={
            <PrivateRoutes>
              <SubscriptionManagement />
            </PrivateRoutes>
          }
        />

        <Route
          path="/subscription-management/add-subscription"
          element={
            <PrivateRoutes>
              <AddSubscription />
            </PrivateRoutes>
          }
        />

        <Route
          path="/subscription-management/edit-subscription/:id"
          element={
            <PrivateRoutes>
              <EditSubscription />
            </PrivateRoutes>
          }
        />

        <Route
          path="/subscription-management/subscription-details/:id"
          element={
            <PrivateRoutes>
              <SubscriptionDetails />
            </PrivateRoutes>
          }
        />

        <Route
          path="/special-management/"
          element={
            <PrivateRoutes>
              <SpecialManagement />
            </PrivateRoutes>
          }
        />

        <Route
          path="/special-management/add-special"
          element={
            <PrivateRoutes>
              <AddSpecials />
            </PrivateRoutes>
          }
        />

        <Route
          path="/special-management/edit-special/:id"
          element={
            <PrivateRoutes>
              <EditSpecials />
            </PrivateRoutes>
          }
        />

        <Route
          path="/user-management"
          element={
            <PrivateRoutes>
              <UserManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/user-management/:type"
          element={
            <PrivateRoutes>
              <UserManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/user-management/user-detail/:id"
          element={
            <PrivateRoutes>
              <UserDetails />
            </PrivateRoutes>
          }
        />
        <Route
          path="/user-management/edit-detail/:id"
          element={
            <PrivateRoutes>
              <EditUser />
            </PrivateRoutes>
          }
        />

        {/* New Advance Work */}
        <Route
          path="/promo-management"
          element={
            <PrivateRoutes>
              <CouponManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/promo-management/add-promo"
          element={
            <PrivateRoutes>
              <AddCoupon />
            </PrivateRoutes>
          }
        />
        <Route
          path="/promo-management/edit-promo/:id"
          element={
            <PrivateRoutes>
              <EditCoupon />
            </PrivateRoutes>
          }
        />
        <Route
          path="/promo-management/promo-detail/:id"
          element={
            <PrivateRoutes>
              <CouponDetail />
            </PrivateRoutes>
          }
        />
         <Route
          path="/asset-management/"
          element={
            <PrivateRoutes>
              <AssetsManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/asset-management/add-asset"
          element={
            <PrivateRoutes>
              <AddAsset />
            </PrivateRoutes>
          }
        />
         <Route
          path="/asset-management/view-asset/:id"
          element={
            <PrivateRoutes>
              <AddAsset />
            </PrivateRoutes>
          }
        />
        <Route
          path="/media"
          element={
            <PrivateRoutes>
              <Media />
            </PrivateRoutes>
          }
        />
        <Route
          path="/roles-management"
          element={
            <PrivateRoutes>
              <RolesManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/roles-management/add-role"
          element={
            <PrivateRoutes>
              <AddRoles />
            </PrivateRoutes>
          }
        />
        <Route
          path="/employee-management"
          element={
            <PrivateRoutes>
              <UserRoles />
            </PrivateRoutes>
          }
        />
        <Route
          path="/roles-management/view-roles"
          element={
            <PrivateRoutes>
              <ViewRoleDetails />
            </PrivateRoutes>
          }
        />
        <Route
          path="/infobox-management"
          element={
            <PrivateRoutes>
              <InfoboxManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/infobox-management/add-infobox"
          element={
            <PrivateRoutes>
              <AddInfobox />
            </PrivateRoutes>
          }
        />
        <Route
          path="/infobox-management/edit-infobox/:id"
          element={
            <PrivateRoutes>
              <EditInfobox />
            </PrivateRoutes>
          }
        />
        <Route
          path="/infobox-management/infobox-detail/:id"
          element={
            <PrivateRoutes>
              <InfoboxDetail />
            </PrivateRoutes>
          }
        />
        <Route
          path="/employee-management/add-user"
          element={
            <PrivateRoutes>
              <AddEmployees />
            </PrivateRoutes>
          }
        />
        <Route
          path="/sub-category-management"
          element={<PrivateRoutes>
            <SubCategoryManagment />
          </PrivateRoutes>}
        />
        <Route
          path="/unautherized-acess"
          element={
            <PrivateRoutes>
              <UnauthorizedResourceError />
            </PrivateRoutes>
          }
        />
        <Route
          path="/upload-management"
          element={
            <PrivateRoutes>
              <UploadManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/upload-management/add-upload"
          element={
            <PrivateRoutes>
              <AddUpload />
            </PrivateRoutes>
          }
        />
        <Route
          path="/platform-management"
          element={
            <PrivateRoutes>
              <PlatformManagement />
            </PrivateRoutes>
          }
        />
        <Route
          path="/reportings/:type/:id"
          element={
            <PrivateRoutes>
              <Reportings />
            </PrivateRoutes>
          }
        />
        {/* Featured Mix */}
        <Route
          path="/featured-mix"
          element={
            <PrivateRoutes>
              <FeaturedMix />
            </PrivateRoutes>
          }
        />
         <Route
          path="/publish-featured-mix"
          element={
            <PrivateRoutes>
              <PublishFeatureMix />
            </PrivateRoutes>
          }
        />
        <Route
          path="/prompt-managment"
          element={
            <PrivateRoutes>
              <PromptsManagement />
            </PrivateRoutes>
          }
        />
        
        {/* <Route
          path="/forgot-password"
          element={
            <ProtectedRoutes>
              <ForgetPassword />
            </ProtectedRoutes>
          }
        />
          <Route
            path="/verification"
            element={
              <ProtectedRoutes>
                <VerificationPassword />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <ProtectedRoutes>
                <ResetPassword />
              </ProtectedRoutes>
            }
          /> */}
          
        <Route
          path="/category-management/add-featured-mix-category"
          element={
            <PrivateRoutes>
              <AddFeaturedMixCategory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/category-management/edit-featured-mix-category/:id"
          element={
            <PrivateRoutes>
              <EditFeaturedMixCategory />
            </PrivateRoutes>
          }
        />
        <Route
          path="/logs-managment"
          element={
            <PrivateRoutes>
              <LogsManagement />
            </PrivateRoutes>
          }
        />


        {/* <Route path="*" element={<Error />} />  */}
      </Routes>
    </BrowserRouter>
  );
};

export default AdminRouter;
